<template>
  <div class="define-menu-wrapper">
    <van-nav-bar
      title="独立菜单配置"
      :border="false"
      left-arrow
      fixed
      @click-left="$router.back()"
    />
    <van-search
      v-model="query"
      placeholder="请输入台账类型名称"
      clearable
      @search="onSearch"
      @clear="onClear"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div>
      <van-empty
        v-if="definedMenusCom.length === 0"
        image="search"
        description="查无数据"
      />
      <div class="search-total">共 {{ definedMenusCom.length }} 条记录</div>
      <div
        v-for="(item, index) in definedMenusCom"
        :key="item.id"
        class="define-menu-item"
        @click="onClickMenu(item)"
      >
        <div
          class="define-menu-item-bg"
          :style="{
            background: `url(${require(`@/assets/images/defineMenu/_0${(index %
              4) +
              1}.png`)})`
          }"
        >
          <span class="iconfont iconfont-caidan"></span>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      query: ""
    };
  },
  computed: {
    ...mapState({
      definedMenus: state => state.menu.definedMenus
    }),
    definedMenusCom() {
      if (this.query) {
        return this.definedMenus.filter(a => a.name.includes(this.query));
      }
      return this.definedMenus;
    }
  },
  methods: {
    onSearch() {},
    onClear() {
      console.log("12323clear");
      this.query = "";
    },
    onClickMenu(item) {
      this.$router.push({
        name: "independent-menu-detail",
        params: {
          id: item.id,
          name: item.name,
          code: item.code
        }
      });
    }
  },
  mounted() {
    console.log("definedMenus", this.definedMenus);
  }
};
</script>

<style lang="scss" scoped>
.define-menu-wrapper {
  padding-top: 60px;
  .search-total {
    text-align: center;
    font-size: 12px;
    color: #8c8f97;
    margin-top: 2px;
  }
  .define-menu-item {
    padding: 0 16px;
    margin-top: 16px;
    &-bg {
      display: flex;
      align-items: center;
      padding: 0 18px;
      height: 88px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      .iconfont {
        margin-right: 18px;
      }
    }
  }
}
</style>
